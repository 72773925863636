/**
 * Created by root on 03.10.17.
 */
import { Component } from '@angular/core';
import { RestService } from '../shared/call.rest.component';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CheckPasswordService} from "../shared/check-password.service";

@Component({
  selector: 'send_rest_password',
  templateUrl: 'send-reset-password-mail.html',
  providers: [ RestService, CheckPasswordService ]
})

export class SendResetMail {
  response: string="";
  public error: string;
  mailSended:boolean=false;
  token: string="";
  message:string;
  isPassTheSame:boolean;
  constructor(private activatedRoute: ActivatedRoute,public _restservice: RestService,private router: Router,private _checkPasswd: CheckPasswordService) {
    this.error="";
    this.mailSended=false;
  }

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.params.subscribe((params: Params) => {
      this.token = params['id'];
    });
  }

  resetPasswordMail(login:string){
    let body={} ;
    if(login){
      body={
        "p_adres":window.location.href,
        "p_email": login,
      };
      if(this.response) {
        var response = this._restservice.postData('rpc_function/pp_autoryzacja.wyslij_email', body)
        response.subscribe(
          res => {
            this.mailSended=true;
            setTimeout((router: Router) => {
              this.router.navigate(['/']);
            }, 5000);
          },
          err => {
            this.error = err;
          }
        )
      } else {
        this.error = "Należy zaznaczyć pole captcha"
      }
    }else {
      this.error = "Należy wypełnić pole e-mail"
    }
  }

  handleCorrectCaptcha(event) {
    this.error = ""
    this.response = event;
  }

  public checkPassword(pass:string,confPass:string){
    this.message = this._checkPasswd.checkPassword(pass,confPass);
    this.isPassTheSame = this._checkPasswd.passTheSame();
  }

  resetPassword(pass:string){
    var body={};

    if(this.isPassTheSame){
      body={
        "p_email":this.token,
        "p_haslo": pass,
      };

        var response = this._restservice.postData('rpc_function/pp_autoryzacja.zmien_haslo', body)
        response.subscribe(
          res => {
            this.mailSended=true;
            setTimeout((router: Router) => {
              this.router.navigate(['/login']);
            }, 5000);
          },
          err => {
            this.error = err;
          }
        )
    }else {
      this.error = "Błąd formularza"
    }
  }


}
