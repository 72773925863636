import { Component , Input , OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { RestService } from '../shared/call.rest.component';
import { Subject } from 'rxjs/Subject';

@Component({
   selector: 'appmenu',
   templateUrl: './menu.component.html',
   providers: [ RestService ]
})


export class MenuComponent implements OnInit{

    public menuDefs:any[];
    public static updateUserStatus: Subject<boolean> = new Subject();

    public initRequest():any{
      let menuMetadata: Observable<any[]>;
      let body = {"p_id":"%"};

      menuMetadata=this._restservice.postData('rpc/pp_menu.ppmp_menu',body);
      menuMetadata.subscribe(
        metadata => {
           this.menuDefs = metadata;
        },
        error => console.log(error)
      );
    }

    constructor( public _restservice: RestService) {
        MenuComponent.updateUserStatus.subscribe(res => {
          this.initRequest();
     });
    }

    ngOnInit() : void{
      this.initRequest();
    }
}