// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
//  backend: 'http://10.19.0.16:6080/PortalBackend/webresources/'
//  backend: 'http://10.0.0.82:6080/PortalBackend/webresources/'
//  backend: 'http://87.101.68.209:6080/PortalBackend/webresources/'
  backend: 'https://portal.teskosteel.eu:8443/'
};
