/**
 * Created by root on 3/21/17.
 */
import { Injectable } from '@angular/core';
import {Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot,} from '@angular/router';
import { tokenNotExpired} from 'angular2-jwt';
import { JwtHelper } from 'angular2-jwt';

@Injectable()
export class AuthGuard implements CanActivate{
  jwtHelper: JwtHelper = new JwtHelper();
  logged_in;

  constructor(private router: Router){
    this.logged_in = !!localStorage.getItem('id_token');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }



  public isLoggedIn(): Boolean {
    var isExpired;
    try{
      isExpired= this.jwtHelper.isTokenExpired (localStorage.getItem('id_token'));
    }catch(err){
      isExpired= true;
    }
      return !isExpired;
    }
}
