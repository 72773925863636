import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelper } from 'angular2-jwt';
import { AuthService } from '../shared/auth.service.';
import { MenuComponent } from './menu.component';
import { Subject } from 'rxjs/Subject';
import { CartComponent } from '../cart/cart.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
  providers: [ AuthService ]
})

export class FullLayoutComponent implements OnInit {
  public logged_in:boolean = false;
  private sub: any;
  public disabled = false;
  public accountName:String;
  public groupId:number;
  public cartCount: any;
  public status: {isopen: boolean} = {isopen: false};
  public static updateUserStatus: Subject<boolean> = new Subject();
  jwtHelper: JwtHelper = new JwtHelper();
  knkt: string='';
  knod: string='';
  constructor(public router: Router, public auth: AuthService){
        FullLayoutComponent.updateUserStatus.subscribe(res => {
          if(localStorage.getItem('selectedKnkt') )
          {
            this.knkt=(JSON.parse(localStorage.getItem('selectedKnkt'))).knktId;
          }else{
            this.knkt=''
          }
          if(localStorage.getItem('selectedKnod')) {
            this.knod = (JSON.parse(localStorage.getItem('selectedKnod'))).knodId;

          }else {
            this.knod='';
          }
          this.logged_in = !!localStorage.getItem('id_token');
          this.cartCount = localStorage.getItem('cartCount'+this.accountName+this.knkt+this.knod) ;
          this.isLoggedIn();
        });
        this.logged_in = !!localStorage.getItem('id_token');
        this.isLoggedIn();
        this.cartCount = localStorage.getItem('cartCount'+this.accountName+this.knkt+this.knod) ;
  }

  public toggled(open: boolean): void {
//    console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }


  public isLoggedIn(): Boolean {
    var isExpired;
    try{
      isExpired= this.jwtHelper.isTokenExpired(localStorage.getItem('id_token'));

    }catch(err){
      isExpired= true;

    }
    if (this.logged_in && !isExpired) {
      var token = localStorage.getItem('id_token');

      this.accountName = this.jwtHelper.decodeToken(token).ppuz_imie+" "+this.jwtHelper.decodeToken(token).ppuz_nazwisko;
      this.groupId=this.jwtHelper.decodeToken(token).ppuz_manager;
      this.logged_in = true;
      return true;
    }else {
      this.accountName = "Gość";
      this.groupId=0;
      this.logged_in=false;
      return false;
    }

  }

  public logout() {
    localStorage.removeItem('id_token');
    this.logged_in=false;
    this.accountName= "Gość";
    MenuComponent.updateUserStatus.next(true);

  }

  ngOnInit(): void {}

  private ngOnDestroy() {
     this.sub.unsubscribe();
   }

}
