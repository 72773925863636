/* * * ./app/comments/components/comment.service.ts * * */
// Imports
import { Injectable, ViewChildren }     from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import {Observable} from 'rxjs/Rx';
import { JwtHelper } from 'angular2-jwt';
import { Router } from '@angular/router';
import { FullLayoutComponent } from '../layouts/full-layout.component';
import { MenuComponent } from '../layouts/menu.component';
import { environment } from '../../environments/environment';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class RestService {
    // Resolve HTTP using the constructor

  jwt: string;
  constructor (private http: Http, public router: Router) {
    this.jwt=localStorage.getItem('id_token');
  }

    private restUrl = environment.backend;


     // Fetch all existing comments
    getData(url: string) : Observable<any[]>{
       // ...using get request
       return this.http.get(`${this.restUrl}${url}`)
                      // ...and calling .json() on the response to return data
                       .map((res:Response) => res.json())
                       //...errors if any
                       .catch((error:any) => Observable.throw(error.json().error || 'Błąd serwera'));

    }

     // Add a new data
    postData (url: string, body): Observable<any[]> {

        let bodyString = JSON.stringify(body); // Stringify payload
        let headers = new Headers(); // ... Set content type to JSON
        headers.append('Content-Type', 'application/json');
        this.jwt=localStorage.getItem('id_token');
        if (this.jwt) {
          headers.append('Authorization', 'Bearer ' + this.jwt );
        } else {
          headers.append('Authorization', '' );
        }
//        headers.append("prefer", "return=representation");
        let options = new RequestOptions({ headers: headers }); // Create a request option

        return this.http.post(`${this.restUrl}${url}`, body, options) // ...using post request
                         .map((res:Response) => { return res.json(); }) // ...and calling .json() on the response to return data
                         .catch((error:any) => {
                              if(error.status === 401){
                                localStorage.removeItem('id_token');
                                FullLayoutComponent.updateUserStatus.next(true);
                                MenuComponent.updateUserStatus.next(true);
                                this.router.navigate(['login']);
                              }
                              var blad = {
                                "blad":error.json().details,
                                "szczegoly":error.json().exceptionMessage
                              }
                              return Observable.throw( blad || 'Błąd serwera')
                            }); //...errors if any
    }

    // Update a data
    updateData (url: string, body: Object): Observable<any[]> {
        let bodyString = JSON.stringify(body); // Stringify payload
        let headers = new Headers(); // ... Set content type to JSON
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + this.jwt );
        headers.append("prefer", "return=representation");
        let options = new RequestOptions({ headers: headers }); // Create a request option

        return this.http.put(`${this.restUrl}${url}/${body['id']}/`, body, options) // ...using put request
                         .map((res:Response) => res.json()) // ...and calling .json() on the response to return data
                         .catch((error:any) => {
                           if(error.status === 401){
                             this.router.navigate(['login']);
                           }
                            return Observable.throw(error.json().error || 'Błąd serwera')
                         }); //...errors if any
    }
    // Delete a data
    removeData (url: string, id:string): Observable<any[]> {
      let headers = new Headers(); // ... Set content type to JSON
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.jwt );
      headers.append("prefer", "return=representation");
      return this.http.delete(`${this.restUrl}${url}/${id}`) // ...using put request
                       .map((res:Response) => res.json()) // ...and calling .json() on the response to return data
                       .catch((error:any) => Observable.throw(error.json().error || 'Błąd serwera')); //...errors if any
    }
}
