import {Component, ElementRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response } from '@angular/http';
import { contentHeaders } from '../shared/headers';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { FullLayoutComponent } from '../layouts/full-layout.component';
import { MenuComponent } from '../layouts/menu.component';
import { environment } from '../../environments/environment';
import {JwtHelper} from "angular2-jwt";

@Component({
  selector: 'login',
  templateUrl: 'login.html'

})

export class Login {
  private logged_in:boolean = false;
  public error: string;
  singleLogin: boolean = false;
  jwtHelper: JwtHelper = new JwtHelper();
  @ViewChild('username') username: ElementRef;
  private email: string;
  constructor(public router: Router, public http: Http){
    this.error='';
    this.logged_in = !!localStorage.getItem('id_token');
  }

  login(event,email,pass){

    event.preventDefault();
    if(!email){
      email=this.email;

    }
    let body = JSON.stringify({email,pass});
    this.http.post(environment.backend+'login', body, {headers: contentHeaders})

      .map((response: Response) => {
        return response.json()
      })
      .map((response: Object) => {
        let token = response['token'];
        if (token) {
          localStorage.setItem('id_token', token);

          localStorage.removeItem('selectedKnkt');
          localStorage.removeItem('selectedKnod');
          localStorage.removeItem(this.jwtHelper.decodeToken(token).ppuz_id + 'selectedIronworks');
          this.logged_in = true;
          FullLayoutComponent.updateUserStatus.next(true);
          MenuComponent.updateUserStatus.next(true);
          this.router.navigate(['dashboard']);
        } else {
          return false;
        }
      })
      .subscribe(
        response => {
          FullLayoutComponent.updateUserStatus.next(true);
          MenuComponent.updateUserStatus.next(true);
          this.router.navigate(['dashboard']);
        },
        error => {
          this.error = error.json().message;
          console.log(error.text());
        }
      );

  }


  relogin(event,email){

    event.preventDefault();
    let body = JSON.stringify({email});
    this.http.post(environment.backend+'rpc_function/f_pp_login_from_admin', body, {headers: contentHeaders})

      .map((response: Response) => {
        return response.json()
      })
      .map((response: Object) => {
        let token = response['token'];
        if (token) {
          localStorage.setItem('id_token', token);
          this.logged_in = true;
          FullLayoutComponent.updateUserStatus.next(true);
          MenuComponent.updateUserStatus.next(true);
          this.router.navigate(['dashboard']);
        } else {
          return false;
        }
      })
      .subscribe(
        response => {
          FullLayoutComponent.updateUserStatus.next(true);
          MenuComponent.updateUserStatus.next(true);
          this.router.navigate(['dashboard']);
        },
        error => {
          this.error = error.json().message;
//          console.log(error.text());
        }
      );

  }

  isLoggedIn(): boolean {
    return this.logged_in;
  }

  signup(event) {
    event.preventDefault();
    this.router.navigate(['signup']);
  }

  singleLoginMode(){
    if(this.singleLogin){
      this.email='kierowcy'
      this.username.nativeElement.value = 'Użytkownik';
    }else{
      this.email = null;
      this.username.nativeElement.value = '';
    }
  }
}
