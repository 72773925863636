import { Injectable } from '@angular/core';

@Injectable()
export class CheckPasswordService {
  isPassTheSame: boolean;
  message: string;

  constructor() {
  }

  passTheSame(){
    return this.isPassTheSame;
  }

  checkPassword(pass: string, confPass: string):string {
    if (pass && confPass) {
      if (pass == confPass) {
        this.isPassTheSame = true;
        var regex = new Array();
        regex.push("[A-Z]"); //Uppercase Alphabet.
        regex.push("[a-z]"); //Lowercase Alphabet.
        regex.push("[0-9]"); //Digit.
        regex.push("[$$!@#$%^&*()<>?/.,]"); //Special Character.
        var passed = 0;

        for (var i = 0; i < regex.length; i++) {
          if (new RegExp(regex[i]).test(pass)) {
            passed++;
          }
        }
        if (passed >= 3 && pass.length >= 8) {
          passed += 5;
        }
        if (passed < 8) {
          this.isPassTheSame = false;
          return 'Hasło musi się składać z co najmniej 8 znaków. Prawidłowe hasło powinno zawierać co najmniej jedną małą literę, jedną wielką literę oraz jedną cyfrę lub znak specjalny.';
        }
        else {
          this.isPassTheSame = true;
          return "";

        }

      } else {
        this.isPassTheSame = false;
        return 'Niezgodne hasła';

      }
    }
  }
}
