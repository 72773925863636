import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';

// Login
import {Login, SendResetMail} from './login';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'components',
        loadChildren: './components/components.module#ComponentsModule'
      },
      {
        path: 'sendResetMail/:id',
        component: SendResetMail,
        data: {
          title: 'Reset request'
        }
      },
      {
        path: 'sendResetMail',
        component: SendResetMail,
        data: {
          title: 'Reset request'
        }
      },

      {
        path: 'login',
        component: Login,
        data: {
          title: 'Login Page'
        }
      },
      {
        path: 'cart',
        loadChildren: './cart/cart.module#CartModule'
      },
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
